import { Scope, SCOPES } from "@cyna/common/constants"

export const routeMap = {
  "/": [],
  "/account": [],
  "/account/settings": [],
  "/callback": [],
  "/emergencyContacts/approve": [],
  // DIST
  "/scope/distributor/msps": [],
  "/scope/distributor/msps/create": [],
  "/scope/distributor/msps/:mspId": ["mspId"],
  "/scope/distributor/msps/:mspId/users": ["mspId"],
  "/scope/distributor/msps/:mspId/users/create": ["mspId"],
  "/scope/distributor/msps/:mspId/users/:userId/edit": ["mspId", "userId"],
  "/scope/distributor/msps/:mspId/reports": ["mspId"],
  "/scope/distributor/msps/:mspId/reports/:reportId": ["mspId", "reportId"],
  "/scope/distributor/msps/:mspId/settings": ["mspId"],
  "/scope/distributor/msps/:mspId/services": ["mspId"],
  "/scope/distributor/msps/:mspId/contacts/emergency": ["mspId"],
  "/scope/distributor/msps/:mspId/contacts/emergency/create": ["mspId"],
  "/scope/distributor/msps/:mspId/contacts/distribution-list": ["mspId"],
  "/scope/distributor/msps/:mspId/customers": ["mspId"],
  "/scope/distributor/msps/:mspId/customers/create": ["mspId"],
  "/scope/distributor/company/apiKeys": [],
  "/scope/distributor/company/apiKeys/create": [],
  "/scope/distributor/company/settings": [],
  "/scope/distributor/company/users": [],
  "/scope/distributor/company/users/:userId": ["userId"],
  "/scope/distributor/company/users/:userId/edit": ["userId"],
  "/scope/distributor/company/users/create": [],
  // CUSTOMER
  "/scope/customer": [],
  "/scope/customer/contacts/distribution-list": [],
  "/scope/customer/contacts/distribution-list/create": [],
  "/scope/customer/contacts/emergency": [],
  "/scope/customer/contacts/emergency/create": [],
  "/scope/customer/reports": [],
  "/scope/customer/reports/:reportId": ["reportId"],
  "/scope/customer/reports/:reportId/preview": ["reportId"],
  "/scope/customer/services": [],
  "/scope/customer/services/:serviceName/users": ["serviceName"],
  "/scope/customer/services/:serviceName/users/create": ["serviceName"],
  "/scope/customer/settings": [],
  "/scope/customer/users": [],
  "/scope/customer/users/create": [],
  "/scope/customer/users/:userId/edit": ["userId"],
  // MSP
  "/scope/msp/customers": [],
  "/scope/msp/customers/:customerId": ["customerId"],
  "/scope/msp/customers/:customerId/contacts/distribution-list": ["customerId"],
  "/scope/msp/customers/:customerId/contacts/emergency": ["customerId"],
  "/scope/msp/customers/:customerId/contacts/emergency/:emergencyContactId/edit":
    ["customerId", "emergencyContactId"],
  "/scope/msp/customers/:customerId/contacts/emergency/create": ["customerId"],
  "/scope/msp/customers/create": [],
  "/scope/msp/customers/:customerId/contacts/distribution-list/:distributionListItemId/edit":
    ["customerId", "distributionListItemId"],
  "/scope/msp/customers/:customerId/contacts/distribution-list/create": [
    "customerId",
  ],
  "/scope/msp/customers/:customerId/reports": ["customerId"],
  "/scope/msp/customers/:customerId/reports/:reportId": [
    "customerId",
    "reportId",
  ],
  "/scope/msp/customers/:customerId/reports/:reportId/preview": [
    "customerId",
    "reportId",
  ],
  "/scope/msp/customers/:customerId/services": ["customerId"],
  "/scope/msp/customers/:customerId/services/:serviceName/users": [
    "customerId",
    "serviceName",
  ],
  "/scope/msp/customers/:customerId/services/:serviceName/users/create": [
    "customerId",
    "serviceName",
  ],
  "/scope/msp/customers/:customerId/settings": ["customerId"],
  "/scope/msp/customers/:customerId/users": ["customerId"],
  "/scope/msp/customers/:customerId/users/:userId": ["customerId", "userId"],
  "/scope/msp/customers/:customerId/users/:userId/edit": [
    "customerId",
    "userId",
  ],
  "/scope/msp/customers/:customerId/users/create": ["customerId"],
  "/scope/msp/company/apiKeys": [],
  "/scope/msp/company/apiKeys/create": [],
  "/scope/msp/company/settings": [],
  "/scope/msp/company/users": [],
  "/scope/msp/company/users/:userId": ["userId"],
  "/scope/msp/company/users/:userId/edit": ["userId"],
  "/scope/msp/company/users/create": [],
  // SESSION
  "/session/forgot-password": [],
  "/session/reset-password": [],
  "/session/sign-in": [],
  "/session/sign-up": [],
} as const

type TRouteMap = typeof routeMap

export type Routes = {
  [TRoutePath in keyof TRouteMap]: TRouteMap[TRoutePath] extends { length: 0 }
    ? (query?: Record<string, string>) => string
    : (
        params: {
          [TRouteParam in TRouteMap[TRoutePath][number]]: string
        },
        query?: Record<string, string>,
      ) => string
}

export const routes = Object.fromEntries(
  Object.entries(routeMap).map(([path, paramNames]) => [
    path,
    paramNames.length > 0
      ? (
          params: Record<(typeof paramNames)[number], string>,
          query?: Record<string, string>,
        ) => {
          const qs = new URLSearchParams({
            ...params,
            ...query,
          }).toString()

          return `${path}/${qs ? `?${qs}` : ""}`
        }
      : (query?: Record<string, string>) => {
          const qs = new URLSearchParams(query).toString()

          return `${path}/${qs ? `?${qs}` : ""}`
        },
  ]),
) as Routes

export const HOME_PATH = "/"

export const CLIENT_PATH = "/scope/msp/customers/:customerId"

export const DIST_CLIENT_PATH = "/scope/distributor/msps/:mspId/customers"

export type RoutePath = keyof Routes

export type PartialRoutes<Route> = Route extends string
  ? Route extends `/${infer Base extends string}/${infer Rest extends string}`
    ?
        | `/${Base}`
        | (Rest extends "" ? never : `/${Base}${PartialRoutes<`/${Rest}`>}`)
    : never
  : never

export type Route = Routes[RoutePath]

export type RouteParams<TRoutePath extends RoutePath> = Parameters<
  Routes[TRoutePath]
>["length"] extends 0 | 1
  ? undefined
  : Parameters<Routes[TRoutePath]>[0]

export const getUrl = <TRoutePath extends RoutePath>(
  path: TRoutePath,
  options: Parameters<Routes[TRoutePath]>[1] extends undefined
    ? {
        query?: Parameters<Routes[TRoutePath]>[0]
      }
    : {
        params: Parameters<Routes[TRoutePath]>[0]
        query?: Parameters<Routes[TRoutePath]>[1]
      },
) => {
  if ("params" in options && options.params) {
    return routes[path](
      options.params,
      options.query,
    ) as `${RoutePath}${`?${string}` | ""}`
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return routes[path](options.query) as `${RoutePath}${`?${string}` | ""}`
}

const pathParamsRegex = /(?<=\/:)\w+/gu

export const getPathParams = (path: string) =>
  (path.match(pathParamsRegex) ?? []) as string[]

export const scopedHomePaths: Record<Scope, RoutePath> = {
  [SCOPES.CUSTOMER]: "/scope/customer",
  [SCOPES.MSP]: "/scope/msp/customers",
  [SCOPES.DISTRIBUTOR]: "/scope/distributor/msps",
}
